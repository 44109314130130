
import { Component, Vue, Prop, Inject, Watch } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import { IHydraulicSystemService } from "@/services";

import { HydraulicSystemInfos } from "@/domain";

@Component
export default class HydraulicSystemAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: () => null })
  system!: HydraulicSystemInfos | null;

  @Prop({ required: true })
  nuclearUnitId!: string | null;

  @Prop({ required: false, default: false })
  includeAll!: boolean;

  @Prop({ required: true })
  disabled!: boolean;

  get hydraulicSystemService() {
    return this.container.resolve<IHydraulicSystemService>(
      S.HYDRAULIC_SYSTEM_SERVICE
    );
  }

  get filteredSystems() {
    const sorted: { id: string | null; name: string; trigram: string }[] =
      _.chain(this.systems)
        .orderBy((o) => o.trigram)
        .map((g) => {
          return {
            id: g.id,
            name: g.name,
            trigram: g.trigram,
          };
        })
        .value();
    if (this.includeAll) {
      sorted.splice(0, 0, { id: null, name: "Tous", trigram: "Tous" });
    }
    return sorted;
  }

  systems: HydraulicSystemInfos[] = [];
  loading: boolean = false;

  mounted() {
    this.shouldFetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.systems = await this.hydraulicSystemService.getMany({
        ids: [],
        nuclearUnitIds: [this.nuclearUnitId!],
        includeEnabled: true,
        includeDisabled: true, // should be false
      });
    } finally {
      this.loading = false;
    }
  }

  shouldFetch() {
    if (this.nuclearUnitId) {
      this.fetch();
    }
  }

  onInput(id: string) {
    this.$emit(
      "update:system",
      _.find(this.systems, (s) => s.id == id)
    );
    this.$emit("input", id);
  }

  @Watch("nuclearUnitId")
  onPropsChange = this.shouldFetch;
}
