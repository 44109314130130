
import { Component, Vue, Prop } from "vue-property-decorator";
import _ from "lodash";

import { LocalisationUi } from "@/domain";

import PowerPlantAutocomplete from "@/components/General/Autocompletes/Localisation/PowerPlantAutocompleteWrapper.vue";
import NuclearUnitAutocomplete from "@/components/General/Autocompletes/Localisation/DependentNuclearUnitAutocomplete.vue";
import HydraulicSystemAutocomplete from "@/components/General/Autocompletes/Localisation/HydraulicSystemAutocomplete.vue";
import EquipmentAutocomplete from "@/components/General/Autocompletes/Localisation/EquipmentAutocomplete.vue";
import ZoneAutocomplete from "@/components/General/Autocompletes/Localisation/ZoneAutocomplete.vue";

@Component({
  components: {
    PowerPlantAutocomplete,
    NuclearUnitAutocomplete,
    HydraulicSystemAutocomplete,
    EquipmentAutocomplete,
    ZoneAutocomplete,
  },
})
export default class ZoneSelection extends Vue {
  @Prop({ required: true })
  value!: LocalisationUi;

  updatePowerPlant(id: string | null) {
    this.$emit("input", {
      powerPlantId: id,
      nuclearUnitId: null,
      systemId: null,
      equipmentId: null,
      zoneId: null,
    });
  }

  updateNuclearUnit(id: string | null) {
    const newValue = _.cloneDeep(this.value);
    newValue.nuclearUnitId = id;
    newValue.systemId = null;
    newValue.equipmentId = null;
    newValue.zoneId = null;
    this.$emit("input", newValue);
  }

  updateSystem(id: string | null) {
    const newValue = _.cloneDeep(this.value);
    newValue.systemId = id;
    newValue.equipmentId = null;
    newValue.zoneId = null;
    this.$emit("input", newValue);
  }

  updateEquipment(id: string | null) {
    const newValue = _.cloneDeep(this.value);
    newValue.equipmentId = id;
    newValue.zoneId = null;
    this.$emit("input", newValue);
  }

  updateZone(id: string | null) {
    const newValue = _.cloneDeep(this.value);
    newValue.zoneId = id;
    this.$emit("input", newValue);
  }

  reset() {
    this.$emit("input", {
      powerPlantId: null,
      nuclearUnitId: null,
      systemId: null,
      equipmentId: null,
      zoneId: null,
    });
  }
}
