
import { Component, Vue, Prop, Inject, Watch } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import { IEquipmentService } from "@/services";

import { EquipmentInfos } from "@/domain";

@Component
export default class EquipmentAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: () => null })
  equipment!: EquipmentInfos | null;

  @Prop({ required: true })
  systemId!: string | null;

  @Prop({ required: false, default: true })
  disabled!: boolean;

  get equipmentService() {
    return this.container.resolve<IEquipmentService>(S.EQUIPMENT_SERVICE);
  }

  get filteredEquipments() {
    return _.chain(this.equipments)
      .orderBy((o) => o.ngram)
      .map((g) => {
        return {
          id: g.id,
          name: `${g.number} (${g.ngram})`,
        };
      })
      .value();
  }

  equipments: EquipmentInfos[] = [];
  loading: boolean = false;

  mounted() {
    this.shouldFetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.equipments = await this.equipmentService.getMany({
        ids: [],
        hydraulicSystemIds: [this.systemId!],
        includeEnabled: true,
        includeDisabled: true, // should be false ???
      });
    } finally {
      this.loading = false;
    }
  }

  shouldFetch() {
    if (this.systemId) {
      this.fetch();
    }
  }

  onInput(equipment: EquipmentInfos) {
    this.$emit("update:equipment", equipment);
    this.$emit("input", equipment.id);
  }

  @Watch("systemId")
  onPropsChange = this.shouldFetch;
}
