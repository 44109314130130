
import { Component, Vue, Prop, Inject, Watch } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { PowerPlantDetails } from "@/domain";
import {
  NuclearUnitAutocomplete,
  NuclearUnitInfos,
  PowerPlantInfos,
} from "prometheus-synced-ui";

import { INuclearUnitService, IPowerPlantService } from "@/services";

@Component({
  components: {
    NuclearUnitAutocomplete,
  },
})
export default class DependentNuclearUnitAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: false, default: () => null })
  nuclearUnit!: NuclearUnitInfos | null;

  @Prop({ required: true })
  powerPlantId!: string | null;

  @Prop({ required: false, default: false })
  includeAll!: boolean;

  get nuclearUnitService() {
    return this.container.resolve<INuclearUnitService>(S.NUCLEAR_UNIT_SERVICE);
  }

  get powerPlantService() {
    return this.container.resolve<IPowerPlantService>(S.POWER_PLANT_SERVICE);
  }

  get powerPlants(): PowerPlantInfos[] {
    return this.powerPlant != null ? [this.powerPlant.toInfos()] : [];
  }

  loading: boolean = false;
  powerPlant: PowerPlantDetails | null = null;
  nuclearUnits: NuclearUnitInfos[] = [];

  mounted() {
    this.shouldFetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.nuclearUnits = await this.nuclearUnitService.getMany({
        ids: [],
        powerPlantIds: [this.powerPlantId!],
        includeEnabled: true,
        includeDisabled: false,
      });
      this.powerPlant = await this.powerPlantService.get(this.powerPlantId!);
    } finally {
      this.loading = false;
    }
  }

  filter(
    item: { id: string | null; name: string; powerPlant: string },
    queryText: string,
    itemText: string
  ) {
    const textOne = item.name.toLowerCase();
    const textTwo = item.powerPlant.toLowerCase();
    const searchText = queryText.toLowerCase();

    return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
  }

  shouldFetch() {
    if (this.powerPlantId) {
      this.fetch();
    }
  }

  onInput(id: string) {
    this.$emit(
      "update:nuclear-unit",
      _.find(this.nuclearUnits, (n) => n.id == id)
    );
    this.$emit("input", id);
  }

  @Watch("powerPlantId")
  onPropsChange = this.shouldFetch;
}
