
import { Component, Vue, Prop, Inject, Watch } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import { IZoneService } from "@/services";

import { ZoneInfos } from "prometheus-synced-ui";

@Component
export default class ZoneAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: () => null })
  zone!: ZoneInfos | null;

  @Prop({ required: true })
  equipmentId!: string | null;

  @Prop({ required: false, default: true })
  disabled!: boolean;

  get zoneService() {
    return this.container.resolve<IZoneService>(S.ZONE_SERVICE);
  }

  get filteredZones() {
    return _.orderBy(this.zones, (z) => z.tag);
  }

  zones: ZoneInfos[] = [];
  loading: boolean = false;

  mounted() {
    this.shouldFetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.zones = await this.zoneService.getMany({
        ids: [],
        equipmentIds: [this.equipmentId!],
        includeEnabled: true,
        includeDisabled: true, // should be false ???
      });
    } finally {
      this.loading = false;
    }
  }

  shouldFetch() {
    if (this.equipmentId) {
      this.fetch();
    }
  }

  onInput(zone: ZoneInfos) {
    this.$emit("update:zone", zone);
    this.$emit("input", zone.id);
  }

  @Watch("equipmentId")
  onPropsChange = this.shouldFetch;
}
