
import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import { IPowerPlantService } from "@/services";

import { PowerPlantInfos } from "prometheus-synced-ui";

import { PowerPlantAutocomplete } from "prometheus-synced-ui";

@Component({
  components: { PowerPlantAutocomplete },
})
export default class PowerPlantAutocompleteWrapper extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: () => null })
  powerPlant!: PowerPlantInfos | null;

  @Prop({ required: false, default: false })
  includeAll!: boolean;

  get powerPlantService() {
    return this.container.resolve<IPowerPlantService>(S.POWER_PLANT_SERVICE);
  }

  powerPlants: PowerPlantInfos[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.powerPlants = await this.powerPlantService.getMany({
        ids: [],
        nuclearUnitIds: [],
        includeEnabled: true,
        includeDisabled: true, // should be false ???
      });
    } finally {
      this.loading = false;
    }
  }
  
  onInput(powerPlant: PowerPlantInfos) {
    this.$emit("update:power-plant", powerPlant);
    this.$emit("input", powerPlant.id);
  }
}
